<template>
  <div>
    <div class="page-header clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/weld2.png')"
      >
      </parallax>
      <div class="container">
        <h2 class="title mt-5">Product / Services</h2>
      </div>
    </div>
    <div class="section">
      <div class="container">

        <div class="row mt-5">
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/vessel1.png"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-vessel">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Vessel</span></h2><br>
                  <!-- <p class="mt-5 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                  <!-- <h6 style="color: orange; margin-top: 70px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/he_service.png"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="85%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-he">
                 <h5 class="mt-4 text-dark">Fabrication of </h5><br> <h2><span style="color: green;" class="font-weight-bold">Heat Exchanger</span></h2>
                 <!-- <p class="mt-4 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                 <!-- <h6 style="color: orange; margin-top: 67px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/detail_product/piping/Titanium Gr. 2 Spool Piping (4).jpg"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-piping">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Piping</span></h2><br>
                  <!-- <p class="mt-5 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                  <!-- <h6 style="color: orange; margin-top: 70px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/detail_product/tower/Ni201 N02201 Distillation Column (2).jpg"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-tower">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Tower</span></h2><br>
                  <!-- <p class="mt-5 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                  <!-- <h6 style="color: orange; margin-top: 70px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/radiant_product.png"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-radiant">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Radiant Tube</span></h2><br>
                  <!-- <p class="mt-5 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                  <!-- <h6 style="color: orange; margin-top: 70px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/detail_product/muffle/MA23 Muffle.jpg"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-mufle">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Muffle</span></h2><br>
                  <!-- <p class="mt-5 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                  <!-- <h6 style="color: orange; margin-top: 70px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <!-- <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/radiant_product.png"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-radiant">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Radiant Tube</span></h2><br>
                </router-link>
              </div>
            </div>
          </div> -->
          <div class="col-md-10 col-xl-6 text-center">
            <div class="row">
              <div class="col-md-10 col-xl-6">
                <img
                  src="img/detail_product/other/Duplex Stainless Steel Screw Conveyor.png"
                  class="rounded animated fadeInDown"
                  width="100%"
                  height="87%"
                />
              </div>
              <div class="col-md-10 col-xl-6 card text-center ml-n3 cards">
                <router-link to="/detail-product-other">
                  <h5 class="mt-5 text-dark">Fabrication of </h5> <br> <h2><span style="color: green;" class="font-weight-bold">Other Equipment</span></h2>
                  <!-- <p class="mt-5 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                  <!-- <h6 style="color: orange; margin-top: 70px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-10 col-xl-12 text-center"> -->
            <div class="row mt-5">
              <div class="col-md-10 col-xl-4">
                <img
                  src="img/maintenance.png"
                  class="rounded animated fadeInDown"
                  height="335"
                />
              </div>
              <div class="col-md-10 col-xl-8 card ml-n3 cards">
                <router-link to="/detail-product-service">
                 <h3 class="mt-4 text-dark text-center "><u>ON SITE SERVICES</u><br></h3>
                 <h3>
                   <span style="color: green;" class="font-weight-bold ml-5"> - Welding Overlay</span><br>
                   <span style="color: green;" class="font-weight-bold ml-5"> - Patching</span><br>
                   <span style="color: green;" class="font-weight-bold ml-5"> - Piping</span><br>
                   <span style="color: green;" class="font-weight-bold ml-5"> - Re-Shell</span><br>
                   <span style="color: green;" class="font-weight-bold ml-5"> - Re-Tubing</span><br>
                   <span style="color: green;" class="font-weight-bold ml-5"> - Shutdown Maintenance</span><br>
                 </h3>
                 <!-- <p class="mt-4 text-dark">There are some reason build the site lorem ipsum dolor sit amet.</p> -->
                 <!-- <h6 style="color: orange; margin-top: 67px; margin-bottom: 38px;">READ MORE</h6> -->
                </router-link>
              </div>
            </div>
          <!-- </div> -->

      </div>
    </div>

    <div class="section mt-n4 mb-4">
      <div class="container">
        <!-- SECOND INTRODUCTION -->
        <div style="color: red:">
          <img
            src="img/product.png"
            class=" img-fluid"/>
        </div>
        <div style="color: red:" class="mt-5">
          <img
            src="img/exp_delivery.png"
            class=" img-fluid"/>
        </div>
        <div style="color: red:" class="mt-5">
          <img
            src="img/exp_material.png"
            class=" img-fluid"/>
        </div>
      </div>
    </div>

    <div class="section section-tabs text-center">
      <div class="">
        <h2 class="title">Our Happy Client</h2>
        <h4>Major Customer in Indonesia</h4>
        <marquee style="font-weight: bold; margin-top: 0px;" class="mt-5">
          <div style="white-space: nowrap;">
            <img height="90" class="mr-5" src="img/our_client/asahimas.png" />
            <img height="90" class="mr-5" src="img/our_client/mcci.png" />
            <img height="90" class="mr-5" src="img/our_client/jfe.png" />
            <img height="90" class="mr-5" src="img/our_client/sulfindo.png" />
            <img height="90" class="mr-5" src="img/our_client/pertamina.png" />
            <img height="90" class="mr-5" src="img/our_client/pupuk.png" />
            <img height="90" class="mr-5" src="img/our_client/smelting.png" />
            <img height="90" class="mr-5" src="img/our_client/sri.png" />
            <img height="90" class="mr-5" src="img/our_client/statomer.png" />
            <img height="90" class="mr-5" src="img/our_client/nsi.png" />
            <img height="90" class="mr-5" src="img/our_client/ineos.png" />
            <img height="90" class="mr-5" src="img/our_client/ikpt.png" />
            <img height="90" class="mr-5" src="img/our_client/lotte.png" />
            <img height="90" class="mr-5" src="img/our_client/loc.png" />
            <img height="90" class="mr-5" src="img/our_client/hmm.png" />
            <img height="90" class="mr-5" src="img/our_client/jgc.png" />
            <img height="90" class="mr-5" src="img/our_client/chiyoda.png" />
            <img height="90" class="mr-5" src="img/our_client/lenzing.png" />
            <img height="90" class="mr-5" src="img/our_client/star_energy.png" />
            <img height="90" class="mr-5" src="img/our_client/sankyu.png" />
            <img height="90" class="mr-5" src="img/our_client/candra.png" />
            <img height="90" class="mr-5" src="img/our_client/sumelectric.png" />
            <img height="90" class="mr-5" src="img/our_client/sunchirin.png" />
            <img height="90" class="mr-5" src="img/our_client/uic.png" />
            <img height="90" class="mr-5" src="img/our_client/ajinomoto.png" />
            <img height="90" class="mr-5" src="img/our_client/basf.png" />
            <img height="90" class="mr-5" src="img/our_client/kao.png" />
          </div>
        </marquee>
        <h4 class="mt-5">Major Customer in Japan</h4>
        <marquee style="font-weight: bold; margin-top: 0px;" class="mt-5">
          <div style="white-space: nowrap;">
            <img height="90" class="mr-5" src="img/our_client/toray.png" />
            <img height="90" class="mr-5" src="img/our_client/tosoh.png" />
            <img height="90" class="mr-5" src="img/our_client/idemitsu.png" />
            <img height="90" class="mr-5" src="img/our_client/sumchem.png" />
            <img height="90" class="mr-5" src="img/our_client/ngk.png" />
            <img height="90" class="mr-5" src="img/our_client/ak.png" />
            <img height="90" class="mr-5" src="img/our_client/kureha.png" />
            <img height="90" class="mr-5" src="img/our_client/jgc_jpy.png" />
            <img height="90" class="mr-5" src="img/our_client/chiyoda.png" />
            <img height="90" class="mr-5" src="img/our_client/sekisui.png" />
            <img height="90" class="mr-5" src="img/our_client/onex.png" />
            <img height="90" class="mr-5" src="img/our_client/mcci.png" />
            <img height="90" class="mr-5" src="img/our_client/jfe.png" />
          </div>
        </marquee>
        <h4 class="mt-5">Major Customer in Singapore</h4>
        <marquee style="font-weight: bold; margin-top: 0px;" class="mt-5">
          <div style="white-space: nowrap;">
            <img height="90" class="mr-5" src="img/our_client/pfizer.png" />
            <img height="90" class="mr-5" src="img/our_client/msd.png" />
            <img height="90" class="mr-5" src="img/our_client/astar.png" />
            <img height="90" class="mr-5" src="img/our_client/src.png" />
            <img height="90" class="mr-5" src="img/our_client/gsk.png" />
            <img height="90" class="mr-5" src="img/our_client/shell.png" />
            <img height="90" class="mr-5" src="img/our_client/sumcheas.png" />
          </div>
        </marquee>
        <h4 class="mt-5">Major Customer in Malaysia</h4>
        <marquee style="font-weight: bold; margin-top: 0px;" class="mt-5">
          <div style="white-space: nowrap;">
            <img height="90" class="mr-5" src="img/our_client/oleo.png" />
            <img height="90" class="mr-5" src="img/our_client/sumelectric.png" />
            <img height="90" class="mr-5" src="img/our_client/basf_petronas.png" />
            <img height="90" class="mr-5" src="img/our_client/toray.png" />
            <img height="90" class="mr-5" src="img/our_client/kao.png" />
            <img height="90" class="mr-5" src="img/our_client/toyo.png" />
            <img height="90" class="mr-5" src="img/our_client/petronas.png" />
          </div>
        </marquee>
        <h4 class="mt-5">Major Customer in Thailand</h4>
        <marquee style="font-weight: bold; margin-top: 0px;" class="mt-5">
          <div style="white-space: nowrap;">
            <img height="90" class="mr-5" src="img/our_client/covestro.png" />
            <img height="90" class="mr-5" src="img/our_client/taniobis.png" />
            <img height="90" class="mr-5" src="img/our_client/tpac.png" />
            <img height="90" class="mr-5" src="img/our_client/tpcc.png" />
            <img height="90" class="mr-5" src="img/our_client/prolific.png" />
            <img height="90" class="mr-5" src="img/our_client/kikai.png" />
            <img height="90" class="mr-5" src="img/our_client/agc.png" />
            <img height="90" class="mr-5" src="img/our_client/surtec.png" />
            <img height="90" class="mr-5" src="img/our_client/jfe.png" />
            <img height="90" class="mr-5" src="img/our_client/scg.png" />
            <img height="90" class="mr-5" src="img/our_client/toshiba.png" />
          </div>
        </marquee>
      </div>
    </div>

    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> with TOKKI </span></h1>
          <div class="row pt-5">
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">Main office & factory  :</span></h6>
              Kawasan Industri Jababeka 1
              Jl.Jababeka XIIB Blok W26
              Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
              Telp: +62-21 893 6733 (Hunting) <br><br>

              Phone : +62 - 21 8936733 (Hunting)<br>
              Fax   : +62 - 21 8936738<br>
              Email : agus.setiyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">2nd office & factory</span></h6>
              Kawasan Industri Krakatau 1,
              Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
              Kota Cilegon, Banten - Indonesia 42443 <br><br>
              
              Phone : +62 - 254 8317244<br>
              Fax   : +62 - 254 8317244<br>
              Email : sutriyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">3rd Branch office</span></h6>
              Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
              Phone : +62 - 31 39935914<br>
              Fax   : +62 - 31 39935914<br>
              Email : sales@tef.co.id<br>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  methods: {
    // toDetailProduct(param){
    //   this.$router.push('/detail-product/'+param)
    // }
  }
};
</script>
<style>
   .animated {
     
      padding-top:0px;
      margin-bottom:0px;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
   }
   
   @-webkit-keyframes fadeInDown {
      0% {
         opacity: 0;
         -webkit-transform: translateY(-20px);
      }
      100% {
         opacity: 1;
         -webkit-transform: translateY(0);
      }
   }
   
   @keyframes fadeInDown {
      0% {
         opacity: 0;
         transform: translateY(-50px);
      }
      100% {
         opacity: 1;
         transform: translateY(0);
      }
   }
   
   .fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
   }


  .cards:hover{
    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.1);
    cursor: pointer;;
  }
</style>
